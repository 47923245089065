import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import Box from '../components/Box';
import Controls from '../components/Controls';
import { Canvas } from 'react-three-fiber';
import { makeStyles } from '@material-ui/core/styles';
import {
  GridList,
  GridListTile,
  GridListTileBar,
  ListSubheader,
  IconButton,
  Paper,
  Tabs,
  Tab,
  AppBar,
  Typography
} from '@material-ui/core';
// import InfoIcon from '@material-ui/icons/Info';
import ReferencesData from '../data/references';

import ConstructeursSvg from '../../static/prospects/constructeurs.svg';
import ArchitectesSvg from '../../static/prospects/architectes.svg';
import BureauxEtudesSvg from '../../static/prospects/bureaux-etudes.svg';
import EntreprisesSvg from '../../static/prospects/entreprises.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // width: 500,
    height: 550,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));


const Home = (props) => {
  const classes = useStyles();

  const servicesMarkdown = props.data.services.edges;
  const solutionsMarkdown = props.data.solutions.edges;
  const json = props.data.allFeaturesJson.edges;
  const [value, setValue] = React.useState(0);

  const nodesBox = new Array(30).map((el, i) => {
    return <Box key={i} position={[i, i, i]} />
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout bodyClass="page-home">
      <SEO title="Conception numérique pour le secteur Construction" />
      <Helmet>
        <meta
          name="description"
          content="TriDyve a pour objectif d'accompagner les entreprises dans leur transformation technologique et les assister pour résoudre leurs problématiques techniques."
        />
      </Helmet>
      <div className="intro pb-4">
        <div className="container">
          <h1>Conception numérique pour le secteur de la Construction</h1>
          <p>
            <strong>TriDyve</strong> a pour objectif d'accompagner les entreprises dans leur transformation technologique et les assister pour résoudre leurs problématiques techniques.
          </p>
        </div>
      </div>

      {/* <div className="iframe-container">
        <iframe
          src="https://www.tensorflow.org/root_c84bd31dac841b25ecf04f60c67536be78475643a529a6181fbcb3d57df5194d.frame?hl=fr"
          frameborder="0"
          allowFullScreen
        >
        </iframe>
      </div>       */}
      <div className="container pt-2">
        <Call button />
      </div>

      {/* <div className="threejs-container">
        <Canvas className='threejs'>
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <Controls enableDamping rotateSpeed={0} dampingFactor={0.1} />
          <Box position={[-1.2, 0, 0]} />
          <Box position={[1.2, 0, 0]} /> */}
      {/* { nodesBox } */}
      {/* <Controls /> */}
      {/* </Canvas>
      </div> */}
      <div className="container pt-5 pt-md-7">
        <div className="row">
          <div className="col-lg-4">
            <h2 className="title-3 text-dark mb-3">Vous êtes ?</h2>
            <p>
              Le but de TriDyve est de permettre la transformation technologique du secteur de la Construction.
              Que vous soyez <strong>maitre d'ouvrage</strong>, <strong>architecte</strong>, <strong>maitre d'oeuvre</strong> ou encore <strong>entreprise générale</strong>, TriDyve peut vous aider à mettre en place des <strong>solutions techniques innovantes à haute valeur ajoutée</strong>.
            </p>
          </div>
          <div className="col-lg-8">
            <div className={classes.root}>
              <div className="prospects-tabs">
                <AppBar
                  position="static"
                  color="default"
                  className="app-bar"
                >
                  <Tabs
                    className="tabs"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Maitre d'ouvrage" {...a11yProps(0)} />
                    <Tab label="Architectes et Maitre d'oeuvre" {...a11yProps(1)} />
                    <Tab label="Bureaux d'études" {...a11yProps(2)} />
                    <Tab label="Entreprises et Artisans" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={ConstructeursSvg} />
                      </div>
                      <div className="col-9">
                        <p>
                          TriDyve implémente des Applications Web sur-mesures permettant aux Maitres d'Ouvrages
                          d'avoir un suivi en temps réel des différentes phases de vie d'un projet
                          de Construction.
                        </p>
                        <p>
                          Ces solutions permettent d'optimiser les coûts et de faciliter la communication
                          entre les différents intervenants.
                        </p>
                        <Link className="button button-primary mt-2" to="#solutions">Nos solutions</Link>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={ArchitectesSvg} />
                      </div>
                      <div className="col-9">
                        <p>
                          TriDyve développe des plugins vous permettant de connecter
                          vos logiciels métiers tels que <a href="https://archicad.fr/" target="_blank" title="Archicad">Archicad</a> où <a href="https://www.autodesk.fr/products/revit/overview?mktvar002=3360535|SEM|645561709078675798633kwd-295427476226&1088704&gclsrc=aw.ds&&mkwid=suWMVx9DQ|pcrid|378595182618|pkw|revit|pmt|e|pdv|c|slid||pgrid|78675798633|ptaid|kwd-295427476226|pid|&utm_medium=cpc&utm_source=google&utm_campaign=GGL_DEC_Revit_EMEA_FR_eComm_SEM_BR_New_MIX_ADSK_3360535_Revit&utm_term=revit&utm_content=suWMVx9DQ|pcrid|378595182618|pkw|revit|pmt|e|pdv|c|slid||pgrid|78675798633|ptaid|kwd-295427476226|&gclid=EAIaIQobChMIzLKW8LuN6AIVB7LtCh0Gdg08EAAYASAAEgKpOPD_BwE&s_kwcid=AL!11172!3!378595182618!e!!g!!revit&ef_id=XkPPpwAAAaFQaBTJ:20200309131139:s" target="_blank" title="Revit">Revit</a> aux autres acteurs de la Construction.
                        </p>
                        <p>
                          Ces solutions permettent d'optimiser les coûts et de faciliter la communication
                          entre les différents intervenants.
                        </p>
                        <Link className="button button-primary mt-2" to="#solutions">Nos solutions</Link>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={BureauxEtudesSvg} />
                      </div>
                      <div className="col-9">
                        <p>
                          Les Bureaux d'études techniques ont un rôle de validation et
                          d'optimisation des projets de construction.
                          Ils sont amenés à développer des solutions et rédiger des notes de calculs.
                        </p>
                        <p>
                          TriDyve aide à convertir ces outils type excels ou logiciels en Applications Web.
                          Permettant ainsi aux bureaux d'études de conserver le savoir faire en interne (
                          <a href="https://www.tridyme.com/fr/blog/4-raisons-de-passer-de-excel-aux-web-apps/" target="_blank">4 raisons de passer de l’Excel aux Applications Web</a>)
                          et de capitaliser dessus.
                        </p>
                        <p>
                          Le bureau d'étude passe ainsi de centre de coûts en centre de profits.
                        </p>
                        <Link className="button button-primary mt-2" to="#solutions">Nos solutions</Link>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={EntreprisesSvg} />
                      </div>
                      <div className="col-9">
                        <p>
                          Depuis les relevés de bâtiments et les premières esquisses de conception
                          jusqu'à la réception de chantier et la clôture des comptes,
                          TriDyve peut vous accompagner sur l'ensemble des tâches propres à votre activité
                          en mettant en place des solutions sur-mesure.
                        </p>
                        <p>
                          Ces solutions permettent d'optimiser les coûts et de faciliter la communication
                          entre les différents intervenants.
                        </p>
                        <Link className="button button-primary mt-2" to="#solutions">Nos solutions</Link>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">Nos Compétences</h2>
          </div>
          {servicesMarkdown.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                  </h2>
                  <p>{edge.node.frontmatter.intro}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12">
            <p>
              Nous utilisons les dernières technologies, notamment:
          </p>
          </div>

          <div className="col-3 text-center">
            <img title="react.js" className="technos-img" src="../../technos/react.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="node.js" className="technos-img" src="../../technos/nodejs.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="python" className="technos-img" src="../../technos/python.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="docker" className="technos-img" src="../../technos/docker.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="c++" className="technos-img" src="../../technos/c.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="sass" className="technos-img" src="../../technos/sass.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="html 5" className="technos-img" src="../../technos/html-5.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="raspberry pi" className="technos-img" src="../../technos/raspberry-pi.svg" />
          </div>
          <div className="col-12">
            <p>
              Et nos ingénieurs et développeurs sont formés aux outils du secteur de la Construction, notamment:
          </p>
          </div>
          <div className="col-3 text-center">
            <img title="Revit" className="technos-img-1" src="../../technos/autodesk-revit.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="Archicad" className="technos-img" src="../../technos/archicad.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="IFC" className="technos-img" src="../../technos/ifc.svg" />
          </div>
          <div className="col-3 text-center">
            <img title="SAP2000" className="technos-img-1" src="../../technos/sap2000.svg" />
          </div>
          {/* <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/services">
              Voir tous nos services
            </Link>
          </div> */}
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 id="solutions" className="title-3 text-dark mb-4">Nos Solutions</h2>
          </div>
          {solutionsMarkdown.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 col-lg-4 mb-2">
              <div className="feature">
                {edge.node.frontmatter.image && (
                  <div className="feature-image">
                    <img src={withPrefix(edge.node.frontmatter.image)} />
                  </div>
                )}
                <h2 className="feature-title">{edge.node.frontmatter.title}</h2>
                <div className="feature-content">{edge.node.frontmatter.intro}</div>
                {/* <Link to={edge.node.frontmatter.path}>Plus d'infos</Link> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Nos Réalisations</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h4 className="title-4 text-dark mb-4"><a href="https://www.tridyme.com/fr" target="_blank" title="TriDyme">TriDyme: Applications Web</a></h4>
            <p>
              TriDyve a développé <a href="https://www.tridyme.com/fr" target="_blank" title="TriDyme"><strong>TriDyme</strong></a> qui est la première marketplace ouverte d'<Link to="/solutions/applications-web">Applications Cloud</Link> pour le secteur de la Construction.
            </p>
            <p>
              On y trouve des applications permettant notamment de <a href="https://www.tridyme.com/fr/webapps/" target="_blank" title="TriDyme">calculer des structures</a> ainsi que de visualiser des <a href="https://www.tridyme.com/fr/webapps/" target="_blank" title="Maquettes BIM">maquettes BIM</a>.
            </p>
            <p>
              <strong>Exemple :</strong> <a href="http://app.tridyme.com/structure3d" target="_blank" title="TriDyme">Structure 3D</a> est la première Applications de Calcul de Structure utilisable <strong>100% en ligne</strong>.
            </p>
            <div className="col-12 text-center">
              {/* <Link className="button button-primary mt-2" to="/references">
                Voir nos réalisations
              </Link> */}
            </div>
            <br />
          </div>
          <div className="col-md-6">
            <div className="reference-video-container">
              <iframe className="reference-video" title="TriDyme - Présentation" width="560" height="310" src="https://www.youtube.com/embed/pbTfBqjuSQA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
              </iframe>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="reference-video-container">
              <iframe className="reference-video" title="TriDyme - Présentation" width="560" height="310" src="https://www.youtube.com/embed/QEFzM-Fm_Jc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
              </iframe>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="title-4 text-dark mb-4"><Link to='/references'>Les Plugins</Link></h4>
            <p>
              TriDyve a développé des plugins pour des logiciels du secteur de la Construction
              comme <a href="https://archicad.fr/" target="_blank" title="Archicad">Archicad</a> ou <a href="https://www.autodesk.fr/products/revit/overview?mktvar002=3360535|SEM|645561709078675798633kwd-295427476226&1088704&gclsrc=aw.ds&&mkwid=suWMVx9DQ|pcrid|378595182618|pkw|revit|pmt|e|pdv|c|slid||pgrid|78675798633|ptaid|kwd-295427476226|pid|&utm_medium=cpc&utm_source=google&utm_campaign=GGL_DEC_Revit_EMEA_FR_eComm_SEM_BR_New_MIX_ADSK_3360535_Revit&utm_term=revit&utm_content=suWMVx9DQ|pcrid|378595182618|pkw|revit|pmt|e|pdv|c|slid||pgrid|78675798633|ptaid|kwd-295427476226|&gclid=EAIaIQobChMIzLKW8LuN6AIVB7LtCh0Gdg08EAAYASAAEgKpOPD_BwE&s_kwcid=AL!11172!3!378595182618!e!!g!!revit&ef_id=XkPPpwAAAaFQaBTJ:20200309131139:s" target="_blank" title="Revit">Revit</a>.
            </p>
            <p>
              Ces plugins permettent d'adapter ces logiciels aux évolutions des besoins du secteur.
            </p>
            <p>
              <strong>Exemple:</strong> Le plugin Archicad de <a href="https://www.datbim.com/" target="_blank" title="datBIM">datBIM</a> permettant aux Architectes
              d'enrichir leur maquette numérique avec des <strong>objets BIM</strong>.
            </p>
            <br />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/references">
              Voir nos réalisations
            </Link>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
            intro
          }
          excerpt
        }
      }
    }
    solutions: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/solutions/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            image
            date(formatString: "DD MMMM YYYY")
            intro
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`;

export default Home;


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-panel">{children}</div>}
    </Typography>
  )
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}