import ConcreteSectionReinforcementImage from '../../static/book/concrete-section-reinforcement-880x880.png';
import ConcreteSectionTypesImage from '../../static/book/concrete-section-types-880x880.png';
import ConcreteSectionUlsImage from '../../static/book/concrete-section-uls-880x880.png';
import SteelSectionGeometryImage from '../../static/book/steel-section-geometry-880x880.png';
import SteelSectionResultsImage from '../../static/book/steel-section-results-880x880.png';
import Structure3dImmeubleGeometryImage from '../../static/book/structure-3d-immeuble-geometry-880x880.png';
import Structure3dImmeubleSollicitationsImage from '../../static/book/structure-3d-immeuble-sollicitations-880x880.png';
import Structure3dProjectsImage from '../../static/book/structure-3d-projects-880x880.png';
import Structure3dTourEiffelImage from '../../static/book/structure-3d-tour-eiffel.png';
import Structure3dPyramideLouvreImage from '../../static/book/structure-3d-pyramide-louvre.png';
import DatBimArchicadPluginImage from '../../static/book/datbim-plugin-archicad.png'
import DatBimArchicadPlugin1Image from '../../static/book/datbim-plugin-archicad-1.png'

const referenceData = {
  webApps: [
    {
      img: '',
      title: 'TriDyme - Presentation',
      author: 'TriDyve',
      cols: 4,
      featured: true,
      video: "https://www.youtube.com/embed/pbTfBqjuSQA" 
    },
    {
      img: Structure3dImmeubleGeometryImage,
      title: 'TriDyme - Structure 3D',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: Structure3dImmeubleSollicitationsImage,
      title: 'TriDyme - Structure 3D',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: '',
      title: "datBIM - connection à l'API",
      author: 'TriDyve',
      cols: 4,
      featured: true,
      video: "https://www.youtube.com/embed/nj70t-GaLPg" 
    },
    {
      img: Structure3dProjectsImage,
      title: 'TriDyme - Structure 3D',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: Structure3dTourEiffelImage,
      title: 'TriDyme - Structure 3D',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: Structure3dPyramideLouvreImage,
      title: 'TriDyme - Pyramide Louvre',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: ConcreteSectionReinforcementImage,
      title: 'TriDyme - Concrete Section',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: ConcreteSectionTypesImage,
      title: 'TriDyme - Concrete Section',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: ConcreteSectionUlsImage,
      title: 'TriDyme - Concrete Section',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: SteelSectionGeometryImage,
      title: 'TriDyme - Steel Section',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: SteelSectionResultsImage,
      title: 'TriDyme - Steel Section',
      author: 'TriDyve',
      cols: 4,
      video: "",
    }
  ],
  plugins: [
    {
      img: '',
      title: 'TriDyme - plugin pour Archicad',
      author: 'TriDyve',
      cols: 4,
      featured: true,
      video: "https://www.youtube.com/embed/QEFzM-Fm_Jc" 
    },
    {
      img: DatBimArchicadPluginImage,
      title: 'datBIM - plugin pour Archicad',
      author: 'TriDyve',
      cols: 4,
      video: "",
    },
    {
      img: DatBimArchicadPlugin1Image,
      title: 'datBIM - plugin pour Archicad',
      author: 'TriDyve',
      cols: 4,
      video: "",
    }
  ]
};

export default referenceData;
